// External dependencies
import { Div } from 'atomize';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Local dependencies
import { LocalizedStringMapInput } from '@components/courses/types';
import ImageUploader from '../ImageUploader';
import { ImageField } from '../models';

interface ImageUploaderList {
  imageErrors: any;
  imageList: ImageField[];
  onImageChange: Function;
  parents: LocalizedStringMapInput;
}

function ImageUploaderList({
  imageList,
  parents,
  imageErrors,
  onImageChange,
}: ImageUploaderList) {
  const { t } = useTranslation();

  return (
    <>
      {imageList.map((field, index) => (
        <Div key={index} w="100%" h="250px">
          <ImageUploader
            label={t(field.label)}
            name={field.name}
            onChange={onImageChange}
            value={parents?.[field.language]}
            error={imageErrors?.[field.language]}
          />
        </Div>
      ))}
    </>
  );
}

export default ImageUploaderList;
