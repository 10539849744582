// External dependencies
import { Div } from 'atomize';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Local dependencies
import { LocalizedVideoMapInput } from '@components/courses/types';
import VideoInput, { FileEventTypes } from '../VideoUploader';
import { VideoField } from '../models';

interface VideoUploaderList {
  parents: LocalizedVideoMapInput;
  uploadVideoToS3: (name: string, e: FileEventTypes) => void | any;
  videoErrors: any;
  videoList: VideoField[];
}

export default function VideoUploaderList({
  parents,
  uploadVideoToS3,
  videoErrors,
  videoList,
}: VideoUploaderList) {
  const { t } = useTranslation();

  return (
    <>
      {videoList.map((el, index) => (
        <Div key={index} w="100%" h="250px">
          <VideoInput
            label={t(el.label)}
            name={el.name}
            onChange={uploadVideoToS3}
            value={parents?.[el.language]?.url}
            error={videoErrors?.[el.language]}
          />
        </Div>
      ))}
    </>
  );
}
