import { Div, Icon, Notification, Row, Text } from 'atomize';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '@app/redux/store';
import Header from '@shared/ui/admin/header/Header';
import Button from '@shared/ui/button';
import FormField from '@shared/ui/form/FormField';
import ErrorPopup from '@shared/ui/popup/ErrorPopup';
import Popup from '@shared/ui/popup/Popup';
import SuccessPopup from '@shared/ui/popup/SuccessPopup';
import { FileEventTypes } from './VideoUploader';
import {
  descriptionFields,
  fieldsForValidate,
  imageFields,
  nameFields,
  videoFields,
} from './models';
import {
  createCourseRequest,
  createCourseUpdateFields,
  resetError,
} from './redux/actions';
import FormFieldList from './ui/FormFieldList';
import ImageUploaderList from './ui/ImageUploaderList';
import VideoUploaderList from './ui/VideoUploaderList';

export default function CreateCourse() {
  const {
    course,
    error,
    isSuccess,
    loading,
    nameRuError,
    nameEnError,
    nameKyError,
    descriptionEnError,
    descriptionRuError,
    descriptionKyError,
    imageEnError,
    imageRuError,
    imageKyError,
    videoEnError,
    videoRuError,
    videoKyError,
    ageRestrictionsError,
    coursePriceError,
  } = useSelector((state: RootState) => state.createCourse);

  const [isValidateError, setValidateError] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const namesErrors = {
    ru: nameRuError,
    ky: nameKyError,
    en: nameEnError,
  };
  const descriptionErrors = {
    ru: descriptionRuError,
    ky: descriptionKyError,
    en: descriptionEnError,
  };
  const imageErrors = {
    ru: imageRuError,
    ky: imageKyError,
    en: imageEnError,
  };
  const videoErrors = {
    ru: videoRuError,
    ky: videoKyError,
    en: videoEnError,
  };

  function updateFields(e) {
    const { name, value } = e.target;

    dispatch(createCourseUpdateFields({ [name]: value }));
  }

  function onImageChange(name: string, event: FileEventTypes) {
    const url =
      'https://static.wikia.nocookie.net/naruto/images/3/34/Sasuke_Part_1.jpg/revision/latest?cb=20210404192311&path-prefix=ru';

    // For now, I left it like that, then you need to upload it to S3
    dispatch(createCourseUpdateFields({ [name]: url }));
  }

  async function uploadVideoToS3(name: string, event: FileEventTypes) {
    const url = 'https://';

    // For now, I left it like that, then you need to upload it to S3
    dispatch(createCourseUpdateFields({ [name]: url }));
  }

  function getNestedValue(obj, path) {
    return path.split('.').reduce((value, key) => value[key], obj);
  }

  function onSubmit() {
    fieldsForValidate.forEach((field) => {
      const value = getNestedValue(course, field.path);

      dispatch(createCourseUpdateFields({ [field.name]: value }));
    });

    const isEmptyFields = fieldsForValidate.some(
      (field) => !getNestedValue(course, field.path),
    );

    if (isEmptyFields) {
      setValidateError(true);
    } else {
      dispatch(createCourseRequest(course));
    }
  }

  return (
    <Div className="container" m="0 auto" p="50px 0">
      <Header />
      <Div p={{ t: '80px' }}>
        <Div d="flex" align="center" cursor="pointer" p="20px 0">
          <Icon name="LeftArrow" size="50px" />
          <Link to="/admin/courses">
            <Text textSize="subtitle" textWeight="600">
              <Trans>back</Trans>
            </Text>
          </Link>
        </Div>
        <Div>
          <Text textWeight="600" textSize="heading" p={{ t: '30px' }}>
            <Trans>courseTitle</Trans>
          </Text>
          <Div p="20px 30px" d="flex" style={{ gap: '40px' }}>
            <FormFieldList
              fieldList={nameFields}
              parents={course.name}
              errors={namesErrors}
              updateFields={updateFields}
            />
          </Div>
        </Div>
        <Div>
          <Text textWeight="600" textSize="heading">
            <Trans>description</Trans>
          </Text>
          <Div p="20px 30px" d="flex" style={{ gap: '40px' }}>
            <FormFieldList
              fieldList={descriptionFields}
              parents={course.description}
              errors={descriptionErrors}
              updateFields={updateFields}
            />
          </Div>
        </Div>
        <Div d="flex" p="0 30px" style={{ gap: '40px' }}>
          <Row style={{ whiteSpace: 'nowrap' }} w="34%">
            <FormField
              bg="basic_border_color"
              inputType="number"
              label={t('ageRestrictions')}
              inputName="ageRestrictions"
              inputValue={course.ageRestrictions}
              onChange={updateFields}
              error={ageRestrictionsError}
            />
          </Row>
          <Row>
            <FormField
              bg="basic_border_color"
              inputType="number"
              inputName="coursePrice"
              label={t('coursePrice')}
              inputValue={course.coursePrice.value}
              onChange={updateFields}
              error={coursePriceError}
            />
          </Row>
        </Div>
        <Div>
          <Text textWeight="600" textSize="heading" m={{ t: '40px' }}>
            <Trans>image</Trans>
          </Text>
          <Div d="flex" style={{ gap: '20px' }} p="20px 20px">
            <ImageUploaderList
              imageList={imageFields}
              parents={course.image}
              imageErrors={imageErrors}
              onImageChange={onImageChange}
            />
          </Div>
        </Div>
        <Div>
          <Text textWeight="600" textSize="heading" m={{ t: '40px' }}>
            <Trans>Video</Trans>
          </Text>
          <Div d="flex" style={{ gap: '20px' }} p="20px 20px">
            <VideoUploaderList
              videoList={videoFields}
              parents={course.video}
              videoErrors={videoErrors}
              uploadVideoToS3={uploadVideoToS3}
            />
          </Div>
        </Div>
        <Div
          p="50px 20px"
          maxW="600px"
          d="flex"
          w="100%"
          style={{ gap: '20px' }}
        >
          <Button typeColor="primary" onClick={onSubmit}>
            <Trans>create</Trans>
          </Button>
          <Button typeColor="secondary">
            <Trans>cancel</Trans>
          </Button>
          <Notification
            bg="red"
            isOpen={isValidateError}
            onClose={() => setValidateError(false)}
            prefix={
              <Icon
                name="CloseSolid"
                color="white"
                size="18px"
                m={{ r: '0.5rem' }}
              />
            }
          >
            <Trans>RequiredFieldException</Trans>
          </Notification>
        </Div>
      </Div>
      {loading && (
        <Popup>
          <Div d="flex" justify="center" align="center" p="10px 50px 40px">
            <Icon name="Loading" size="40px" />
          </Div>
        </Popup>
      )}

      {error && (
        <ErrorPopup
          title="error"
          maxW="600px"
          submitText="close"
          onSubmit={() => dispatch(resetError())}
        >
          <Div>{error.message}</Div>
        </ErrorPopup>
      )}

      {isSuccess && (
        <SuccessPopup
          title="ready"
          submitText="complete"
          onSubmit={() => navigate('/admin/courses/')}
        >
          <Text>
            <Trans>courseCreatedSuccessfully</Trans>
          </Text>
        </SuccessPopup>
      )}
    </Div>
  );
}
