// External dependencies
import { Row } from 'atomize';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Local dependencies
import { LocalizedStringMapInput } from '@components/courses/types';
import FormField from '@shared/ui/form/FormField';
import { DescriptionField, NameField } from '../models';

interface FormFieldList {
  errors: any;
  fieldList: NameField[] | DescriptionField[];
  parents: LocalizedStringMapInput;
  updateFields: Function;
}

export default function FormFieldList({
  fieldList,
  parents,
  errors,
  updateFields,
}: FormFieldList) {
  const { t } = useTranslation();

  return (
    <>
      {fieldList.map((field) => (
        <Row key={field.name} w="100%">
          <FormField
            bg="basic_border_color"
            inputName={field.name}
            inputType={field.type}
            label={t(field.label)}
            inputValue={parents[field.language]}
            onChange={updateFields}
            error={errors?.[field.language]}
          />
        </Row>
      ))}
    </>
  );
}
